<template>
    <div>
        <nav-bar></nav-bar>
        <service-support-banner>
            星戟<br> 应用市场
        </service-support-banner>
        <div>
          <div class="MarketApplication-header">星戟市场应用 </div>
          <div class="MarketApplication-header-description">应用开发落地解决专家</div>
          <div class="MarketApplication-space">
            <div  class="MarketApplication" v-for="(item,index) in MarketApplication" :key="index">
              <div class="MarketApplication-bg">
                <div class="MarketApplication-title">{{item.title}}</div>
              </div>
              <div v-for="(list,i) in item.description" :key="i" class="MarketApplication-description">
               {{list}}
              </div>
            </div>
          </div>   
        </div>
        <div>
          <map-company></map-company>
        </div>   
        <Footer></Footer>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  　import { reactive, onMounted,toRefs ,ref,nextTick, computed } from 'vue'
import navBar from '@/components/NavBar'
import Footer from '@/components/Footer'
import ServiceSupportBanner from '@/components/ServiceSupportBanner'
import MapCompany from '@/components/map'
import { useRouter } from 'vue-router';
  export default {
    name: 'Home',
    components: {
      navBar,
      Footer,
      ServiceSupportBanner,
      MapCompany
    },
  
    setup() { 
        const router = useRouter()
      const useData = reactive({
        MarketApplication: [
          {
            title: "TOG",
            description: ['能够根据政府、企业需求，快速定制部署， 支撑内部数据应用。',' 特殊客户需要的边缘云建设，支持如军队 信息化野外作战、训练的边缘云计算， 恶劣条件下实施应急救援指挥等。 '],
          },
          {
            title: "TOB",
            description: ['可为数据中心开发运营商，提供一体化产销运解决方案。',' 为互联网、金融机构，提供稳定可靠的全链路综合服务。 ','为各类企业，提供稳定可靠的全链路综合服务。'],
          },
          {
            title: "TOC",
            description: ['为个人提供微型和超微型边云计算模块单元，满足日益增长的数据处理需求，为元宇宙的实现奠定基础。  '],
          }
        ],
      
      })
      const goTo = (id) => {
      router.push({ path: '/Item', query: { id } })
      }
      const go = (path) => { 
      router.push({ path: path })
      }
    return {
        ...toRefs(useData),
      goTo,
      go
    }
  }
}
  </script>
  <style scoped>
.MarketApplication{
  width: 364px;
margin: 20px 0px;

}
.MarketApplication-header{
  font-size: 45px;font-weight: bold;padding: 130px 0px 28px;text-align: center
}
.MarketApplication-header-description{
  font-size: 25px;color: #969FA9;text-align: center;
}
.MarketApplication-space{
  margin: 90px 0px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
}
.MarketApplication-title{
  font-size: 36px;text-align: center;padding: 0 0 30px;font-weight: bold;
  color: #53B578;
}
.MarketApplication-description{
  font-size: 20px;
  color: #999999;
  padding:20px 0px;
  position: relative;
  text-align: center;
}
.MarketApplication-img{
  width: 196px;
  height: 201px;
  margin: 33px auto 40px;display: block;
}
.MarketApplication-specialty{
  text-align: center;
  font-size: 20px;
  color: #53B578;
}
.MarketApplication-bg{
    width: 280px;
    height: 180px;
    background: url('../assets/images/service-support/MarketApplicationBg.png') no-repeat;
    background-size: cover;
    margin: 0 auto;
}
</style>
  